<template>
  <div class="flow">
    <PageHeader />
    <div class="header-top">
      <Header />
      <div class="header-text">
        <div class="header-title">
          广告效果
        </div>
        <div class="header-sign">
          让每一个产品的核心价值都能触动人心！
        </div>
      </div>
    </div>
    <div class="content-list">
      <div class="content">
        <div class="section">
          <div class="section-info">
            <div class="info-num">
              01
            </div>
            <div class="info-title">
              曝光转化更高
            </div>
            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-desc">
                自主口读推广产品的核心价值口令，通过边朗读边理解与自我声音的倾听，双重方式进行产品价值传递。语音主动交互形式进行推广产品的价值深度理解。
              </div>
              <div class="info-text-desc">
                在了解推广产品价值的基础上可更轻松做决策，保证流量自然有效且深度转化。
              </div>
            </div>
          </div>

          <div class="section-imgs">
            <img
              class="effect1"
              src="../img/effect1.png"
              alt=""
            >
          </div>
        </div>
        <div class="section">
          <div class="section-imgs">
            <img
              class="effect2"
              src="../img/effect2.png"
              alt=""
            >
          </div>
          <div class="section-info">
            <div class="info-num">
              02
            </div>
            <div class="info-title">
              100%真实流量
            </div>
            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-desc">
                需用户自主朗读口令文案，这种主动型语音交互通过声纹进行了活体检测，在风控体系外用产品自身特性确保流量真实性。
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-info">
            <div class="info-num">
              03
            </div>
            <div class="info-title">
              合理成本
            </div>
            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-desc">
                按oCPR实际效果出价，只有用户产生指定行为才扣费，合理有效控制广告投放成本，数据实时跟踪，出价预算随时调整，超出成本还可进行保价赔付。
              </div>
            </div>
          </div>

          <div class="section-imgs">
            <img
              class="effect3"
              src="../img/effect3.png"
              alt=""
            >
          </div>
        </div>
        <div class="section">
          <div class="section-imgs">
            <img
              class="effect4"
              src="../img/effect4.png"
              alt=""
            >
          </div>
          <div class="section-info">
            <div class="info-num">
              04
            </div>
            <div class="info-title">
              灵活的素材配置
              满足不同广告转化需求
            </div>
            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-desc">
                详情页介绍支持真人播音+图片展示或视频展示+字幕展示形式，灵活的素材配置满足不同广告配置与转化需求。
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-info">
            <div class="info-num">
              05
            </div>
            <div class="info-title">
              精准定向
            </div>
            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-desc">
                通过声音可精准识别用户年龄、性别等属性，为广告精准投放提供算法支持，让广告推送给有需要的用户。
              </div>
            </div>
          </div>

          <div class="section-imgs">
            <img
              class="effect5"
              src="../img/effect5.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
    <div class="join-box">
      <div class="join-title">
        现在注册 立即体验新量象服务
      </div>
      <div class="join-desc">
        <!-- 新量象流量价值平台是团队将公司旗下产品核心技术及广告数据库整合成为一个开放引擎，可以便捷接入流量方，轻松为流量主实现流量高收益变现。 -->
      </div>
      <div
        class="join-btn"
        @click="handleClickDialog"
      >
        注册 / 登录
      </div>
    </div>
    <div class="footer-content">
      <PageFooter />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import { mapState } from 'vuex'

export default {
  components: {
    Header,
    PageHeader,
    PageFooter
  },
  data() {
    return {
      phoneText: 0 //语音红包手机模型切换展示
    }
  },
  computed: {
    ...mapState(['showDialog']),
  },
  created(){
    window.scrollTo(0,0);
  },
  methods: {
    // 语音红包手机轮播图片
    chengePhone(e) {
      this.phoneText = e
    },
    handleClickDialog() {
      if (!this.showDialog) {
        this.$store.commit('changeDialog', true)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-top{
  position: relative;
  width: 100%;
  height: 285px;
  background: url('../img/effect-banner.png') no-repeat;
  background-size: cover;
  margin-bottom: 50px;
}
.header-text{
  position: absolute;
  top: 166px;
  left: 260px;
}
.header-title{
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  // font-family: 'fzqk';
}
.header-sign{
  font-size: 24px;
  line-height: 24px;
  color:rgba(255,255,255,0.75)
}
.content{
  width: 70%;
}
.footer-content{
  padding: 0 250px;
}
.section{
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 140px;
  &-info{
    .info-num{
      font-size:50px;
      height: 50px;
      line-height: 50px;
      color:rgba(254,233,203,1);
    }
    .info-title{
      font-size:56px;
      //font-family:'fzqk';
      height: 55px;
      line-height: 55px;
      color:rgba(102,102,102,1);
      margin-bottom: 22px;
      margin-top: -25px;
    }
    .info-line{
      width: 45px;
      height: 8px;
      margin-bottom: 25px;
    }
    .info-text{
      display: flex;
      margin-bottom: 35px;
      align-items: flex-start;
      &-title{
        color: #FF8400;
        font-size: 18px;
        line-height: 18px;
        margin-top: 15px;
      }
      &-desc{
        font-size:18px;
        color:#666666;
        line-height:48px;
        max-width: 456px;
      }
    }
    .info-icon-title{
      font-size:18px;
      color:rgba(255,132,0,1);
      line-height:18px;
      margin-bottom: 30px;
    }
    .info-icon-list{
      display: flex;
      align-items: center;
    }
    .info-icon-item{
      margin-right: 40px;
      font-size:14px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color:rgba(102,102,102,1);
      img{
        width: 56px;
        height: 56px;
        margin-bottom: 7px;
      }
    }
  }
  &-imgs{
    display: flex;
    img{
      width: 225px;
      height: 400px;
    }
    img:nth-child(2){
      margin: -30px -18px 0;
    }
  }
}
.join-box{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height:480px;
  background:linear-gradient(-45deg,rgba(255,96,0,1),rgba(255,144,0,1));
  margin-bottom: 60px;
  box-sizing: border-box;
  padding-top: 83px;
  .join-title{
    height:50px;
    line-height: 50px;
    font-size:50px;
    // font-family: 'fzqk';
    font-weight:bold;
    color:rgba(255,255,255,1);
    margin-bottom: 45px;
  }
  .join-desc{
    font-size:22px;
    color:rgba(255,255,255,1);
    line-height:48px;
    margin-bottom: 47px;
    text-align: center;
  }
  .join-btn{
    width:300px;
    height:68px;
    background:rgba(255,255,255,0.15);
    border:2px solid rgba(255,255,255,1);
    border-radius:34px;
    font-size:30px;
    //font-family:'fzqk';
    font-weight:bold;
    text-align: center;
    line-height: 68px;
    cursor: pointer;
    color:rgba(255,255,255,1);
  }
}
.content-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  .info-title{
    font-size: 24px;
  }
  .info-btn{
    width: 116px;
    height: 59px;
    cursor: pointer;
  }
  .info-text{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 27px;
    .info-text-desc{
      margin-bottom: 30px;
      text-indent: 30px;
    }
  }
  .effect1{
    width: 387px;
    height: 256px;
  }
  .effect2{
    width: 372px;
    height: 327px;
  }
  .effect3{
    width: 393px;
    height: 251px;
  }
  .effect4{
    width: 366px;
    height: 250px;
  }
    .effect5{
    width: 399px;
    height: 269px;
  }
}
@media screen and (max-width: 1440px) {
  .header{
    height: 285px;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
  .content{
    padding: 0 225px;
  }
  .footer-content{
    padding: 0 225px;
  }
  .section{
    padding-top: 77px;
    margin-bottom: 103px;
    &-info{
      .info-num{
        font-size:38px;
        height: 38px;
        line-height: 38px;
      }
      .info-title{
        font-size:24px;
        height: 42px;
        line-height: 42px;
        margin-bottom: 16px;
        margin-top: -20px;
      }
      .info-line{
        width: 34px;
        height: 5px;
        margin-bottom: 19px;
      }
      .info-text{
        margin-bottom: 24px;
        &-title{
          font-size: 14px;
          line-height: 14px;
          margin-top: 11px;
        }
        &-desc{
          max-width: 353px;
          font-size:14px;
          line-height:36px;
        }
      }
      .info-icon-title{
        font-size:14px;
        line-height:14px;
        margin-bottom:23px;
      }
      .info-icon-item{
        margin-right: 30px;
        font-size:12px;
        line-height: 12px;
        img{
          width: 42px;
          height: 42px;
          margin-bottom: 7px;
        }
      }
    }
    &-imgs{
      display: flex;
      img{
        width: 170px;
        height: 302px;
      }
      img:nth-child(2){
        margin: -22px -13px 0;
      }
    }
  }
  .join-box{
    height:360px;
    margin-bottom: 44px;
    padding-top: 62px;
    .join-title{
      height:38px;
      line-height: 38px;
      font-size:38px;
      margin-bottom: 33px;
    }
    .join-desc{
      font-size:16px;
      line-height:36px;
    }
    .join-btn{
      width:222px;
      height:50px;
      background:rgba(255,255,255,0.15);
      border:2px solid rgba(255,255,255,1);
      border-radius:25px;
      font-size:22px;
      line-height: 50px;
    }
  }

}
@media screen and (min-width: 1445px) {
.header-top{
    height: 400px;
}
.header-title{
    font-size: 40px;
    line-height: 36px;
    margin-bottom: 30px;
}
.header-sign{
    font-size: 20px;
  }
}
.main{
        display: flex;
        flex-direction: column;
        .item{
            display: flex;
            flex-direction: column;
            padding: 10px 30px;
            margin-bottom: 20px;
            .flow-path{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 36.45px;
                .flow-path-img{
                    width: 1035px;
                    height: 137px;
                }
                .flow-path-list{
                    display: flex;
                    flex-direction: row;
                    width: 1035px;
                    text-align: center;
                    .flow-path-item{
                        margin-left: 55.93px;
                        .number{
                            font-size: 14px;
                            //font-family: PingFang SC;
                            font-weight: bold;
                            color: #249CEB;
                            line-height: 27px;
                        }
                        .text{
                            font-size: 18px;
                            //font-family: PingFang SC;
                            font-weight: 400;
                            color: #000000;
                            line-height: 27px;
                        }
                    }
                    .secend{
                        margin-left: 195px;
                    }
                    .three{
                        margin-left: 204px;
                    }
                }
            }
            .advantage{
                display: flex;
                flex-direction: column;
                align-items: center;
                 margin-top: 30px;
                .title{
                    width: 350px;
                    height: 27px;
                }
                .title-secend{
                    width: 541px;
                    height: 30px;
                    margin-top: 55px;
                }
                .advantage-list{
                    width: 883px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 53px;
                    .advantage-item{
                        display: flex;
                        flex-direction: column;
                        .header{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 170px;
                            .header-img{
                                width: 96px;
                                height: 96px;
                            }
                            .header-text-main{
                                font-size: 23px;
                                //font-family: FZQingKeBenYueSongS-R-GB;
                                font-weight: 400;
                                color: #FF7800;
                                line-height: 47px;
                            }
                            .header-line{
                                width: 44px;
                                height: 3px;
                                background: #FFDDB8;
                                border-radius: 1px;
                            }
                        }
                        .other-text{
                            margin-top: 17px;
                            width: 170px;
                            font-size: 12px;
                            //font-family: PingFang SC;
                            font-weight: 400;
                            color: #777777;
                            line-height: 21px;
                            text-align: center;
                        }
                    }
                }
            }
            .extension{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 100px;
                .title{
                    width: 418px;
                    height: 27px;
                }
            }
        }
        .handle-extension{
            width: 100%;
            height: 98px;
            background:rgba(0, 0, 0, .4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .btn{
                width: 403px;
                height: 65px;
                background: linear-gradient(90deg, #FF7200, #FFA200);
                border-radius: 32px;
                font-size: 25px;
                //font-family: FZQingKeBenYueSongS-R-GB;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 65px;
                text-align: center;
                cursor: pointer;
            }
        }
}
.extension-main {
    .phone-wrap{
                display: flex;
                justify-content: center;
                margin-bottom:92px;
                margin-top: 68px;
                .model{
                    width: 306px;
                    height: 523px;
                    position: relative;
                    .phone-item{
                        width: 306px;
                        height: 523px;
                        .phone-item-img{
                            width: 306px;
                            height: 523px;
                        }
                    }
                    .phone-item-other{
                            position: absolute;
                            top: 106.48px;
                            left: -247px;
                            width: 321px;
                            height: 95.6px;
                            background-image: url('~@/img/vioce/phone-text-1.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            z-index: 2;
                        .phone-item-text{
                            margin-top: 29px;
                            margin-left: 29px;
                            width: 171px;
                            font-size: 14px;
                            //font-family: FZQingKeBenYueSongS-R-GB;
                            font-weight: bold;
                            color: #FF7800;
                            line-height: 23px;
                        }
                        .phone-item-text-three{
                            margin-left: 134px;
                        }
                    }
                    .phone-item-other-secend{
                        top: 442px;
                        left: -244px;
                        width: 321px;
                        height: 94px;
                        background-image: url('~@/img/vioce/phone-text-2.png');
                    }
                    .phone-item-other-three{
                        top: 21px;
                        left: 242px;
                        width: 313px;
                        height: 188px;
                        background-image: url('~@/img/vioce/phone-text-3.png');
                    }
                    .phone-item-other-fourth{
                        top: 360px;
                        left: 236px;
                        width: 315px;
                        height: 95px;
                        background-image: url('~@/img/vioce/phone-text-4.png');
                    }
                }
            }
}
img{
          width: 100%;
          height: 100%;
}
.fade-enter-active, .fade-leave-active{
            transition:opacity 1s
}
.fade-enter, .fade-leave-to{
    opacity: 0;
}

@media screen and (min-width: 1496px){
  .advantage-list{
      width: 1200px!important;
  }
}
</style>
